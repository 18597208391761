import React from "react";
// import Button from "@mui/material/Button";
import Button from "./Button";
export default function NumPad(props) {
    const options = [
        { value: "1" },
        { value: "2" },
        { value: "3" },
        { value: "4" },
        { value: "5" },
        { value: "6" },
        { value: "7" },
        { value: "8" },
        { value: "9" },
        { value: "0" },
        { value: "00" },
        { value: "000" },
        { value: "back" },
        { value: "clear" },
    ];

    return (
        <>
            <div className=" flex h-full w-full flex-row flex-wrap items-center  justify-around max-lg:h-[125%] max-lg:w-[125%] max-lg:scale-75 ">
                {options.map((itm, idx) => {
                    return (
                        <div key={idx} className="h-[18%] w-[32%] p-1">
                            <Button
                                sx={{ width: "100%", height: "100%", fontSize: "1.5rem" }}
                                variant="contained"
                                onClick={() => {
                                    buttonClickHandler(itm["value"]);
                                }}
                            >
                                {itm["value"]}
                            </Button>
                        </div>
                    );
                })}
                <div className="h-[18%] w-[32%] p-1">
                    <Button
                        sx={{ width: "100%", height: "100%", fontSize: "1.5rem" }}
                        variant="contained"
                        onClick={() => {
                            props.login(props.input);
                        }}
                        id="enterListener"
                        loading={props.loginLoading}
                    >
                        Login
                    </Button>
                </div>
            </div>
        </>
    );
    function buttonClickHandler(e) {
        if (e === "clear") {
            props.setInput("");
        } else if (e === "back") {
            let temp = props.input;
            temp = temp.slice(0, -1);
            props.setInput(temp);
        } else {
            props.setInput(props.input + e);
        }
    }
}
